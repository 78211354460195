.wrapper
	height: 100%
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	padding: var(--common-edge-gap)

.title
	font-size: 4em
	font-weight: 700
